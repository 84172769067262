var home = {

	initialize: function() {
		home.bindEvents();
		home.maquetat();
	},

	bindEvents: function() {
		$(document).ready(home.maquetat);
		$(window).on('resize', home.maquetat);
		$(window).load(home.loadReady);
	},

	maquetat: function() {

		
		header = $('header');
		footer = $('footer');
		details = $('#details');
		section = $('section');
		submenu = $('#submenu');
		nav = $('nav');
		aside = $('aside');
		dins = $('.dins');
		mainT = $('main');
		optiondiv = $('.option div');

		Hwindow = $(window).height();
		Hh4 = $('main h4').outerHeight(true);
		Hsubmenu = submenu.outerHeight(false);
		Hdetails = details.outerHeight(false);
		Hfooter = footer.outerHeight(false);
		Hheader = header.height();
		Hsection = section.height();
		Hmain = mainT.height();
		Wnav = nav.width();
		Waside = aside.width();
		Hoptiondiv2 = (optiondiv.outerHeight(false)*0.5);

		Hfoot10 = Hfooter+10;
		Hheadfoot = Hheader+Hfooter;
		Hmainhf = Hmain+Hheadfoot+30;
		HNoMain = Hheadfoot+Hsubmenu;
		Hdetfoot = Hfooter+Hdetails;
		Hdetfoot150 = Hdetfoot+150;
		Hdinsresta = Hh4+Hheadfoot+15;
		Hdinsdefecte = Hwindow - Hdinsresta;
		homeHdetfoot=Hdetfoot+10;
		homeHdetfoot2m=(homeHdetfoot/2)-10;

		section.css({'min-height':'calc(100vh - '+HNoMain+'px)'});
		$('.amunt').css({'bottom':Hdetfoot150+'px'});

		(function($, viewport){
			if( viewport.is('xs') ) {
				$('body').addClass("xs");
				section.addClass("xs").find('article').css({'margin':'0px'}).find('.option b, .option p').css({'display':'none'});
				Hoptiondiv2 = (optiondiv.outerHeight(false)*0.5);
				footer.addClass("xs");
				dins.addClass("xs");
				$('.option').addClass("xs");

				if($('section').hasClass('xs') == false){
					$('#home article,#home .option').css({'min-height':'calc(100vh - '+homeHdetfoot+'px)'});
				}else{
					$('#home article,#home .option').css({'min-height':'calc(50vh - '+homeHdetfoot2m+'px)'});
				}

			}else{
				section.removeClass("xs");
				footer.removeClass("xs");
				dins.removeClass("xs");
				$('.option').removeClass("xs");

				$('#home article,#home .option').css({'min-height':'calc(100vh - '+Hfoot10+'px)'});
			}
		})(jQuery, ResponsiveBootstrapToolkit);

		optiondiv.css({'margin-top':'-'+Hoptiondiv2+'px'});

	},

    loadReady: function(){
    	$('#preloader').hide();
    	$('.wrap').fadeTo('slow', 1);
	}
}

home.initialize();
